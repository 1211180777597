import React from 'react'
import Layout from '../templates/Master'
import { Box, Grid } from '@mui/material'
// import Hero from '../modules/Hero'
import Content from '../modules/Content'
import Form from '../modules/Form'
import LineBreak from '../components/general/LineBreak'
import Carousel from '../modules/Carousel'
import Share from '../modules/Share'
import Heading from '../modules/Heading'
// import StartCountDown from '../modules/StartCountDown'
// import Media from '../modules/Media'
import SocialMediaPost from '../modules/SocialMediaPost'
import Auction from '../modules/Auction'

const HomePage = () => {
    return (
        <Layout>
            {/* <Hero img1='comicconnect-mark-zuckerberg-rookie-card-day-camp-1992-with-card.jpg' />
            <Media media = {[
                "comicconnect-mark-zuckerberg-rookie-card-front-middle.jpg",
                "comic-connect-1992-mark-zuckerberg-rookie-card-poster-frame-NFT.jpg",
                "comicconnect-mark-zuckerberg-rookie-card-day-camp-1992-bear.jpg" ]}/> 
            <Box textAlign="center" m={8}>
                <Heading as='h1' content='Register for The Mark Zuckerberg Baseball Card and NFT Auction' />
            </Box> */}
            <Auction />
            {/* <LineBreak />
            <Box textAlign="center" mt={8} mb={4}>
                <Heading alignText="center" as='h2' content='Get ready!' />
            </Box>
            <Grid alignItems='center' container="true" justifyContent='center' mb={8}>
                <StartCountDown />
            </Grid> */}
            <LineBreak short/>
            <Content
                alignText="center"
                content="<p><a href='/card'>1/1 Authentic 1992 Mark Zuckerberg Rookie SGS-Graded Baseball card</a> and all documents related to the item’s unique history.</p><p><a className='orange' href='/nft'>1/1 NFT minted on Ethereum of the Authentic 1992 Mark Zuckerberg Rookie SGS-Graded Baseball card</a> and all documents related to the item.</p><p>Read the fascinating, intensely human <a  href='/card'>story behind the Zuckerberg baseball card</a>.</p>" />
            <LineBreak short/>
            <Content
                alignText="center"
                content="<h2>If You’re Already Registered for the Auction</h2><p>Already registered? You can get <a href='/auction'>all the details of the baseball card and NFT auction</a> you’ll need. See our <a className='orange' href='/nft'>overview of NFTs, Digital Collectibles, Blockchain and Cryptocurrency payments</a></p>" />
            <LineBreak short/>
            <Content
                alignText="center"
                content="<h2>If You’re Interested in NFTs but Not Ready to Bid</h2><p>Interested in NFTs but not ready to bid yet? Check out our <a className='orange' href='/nft'>guide to everything you need to know about digital files</a> and sign up for updates below.</p>" />
            <LineBreak short/>
            <Box textAlign="center" mt={8}>
                <Heading as='h3' variant='h3' content='To bid on the Card, make sure you register for the Zuck auction early!' width={['auto', 'auto', 'auto', 'auto', 800]} />
            </Box>
            <Content
                alignText="center"
                content="<p>Don't miss this historic event! Please sign up here to stay up to date and receive sneak previews!</p>"
                width={['auto', 'auto', 'auto', 'auto', 800]}/>
            <Form />
            <LineBreak short />
            <Box textAlign="center" my={8}>
                <Heading as='h3' content='What Mark Zuckerberg said on FB' />
            </Box>
            <SocialMediaPost type='facebook' />
            <LineBreak short/>
            <Box textAlign="center" mt={8}>
                <Heading as='h3' content='What Mark Zuckerberg said on IG' />
            </Box>
            <Content
                alignText="center"
                content="<p>“In honor of expanding digital collectible NFTs to 100 more countries on Instagram and launching new integrations with Coinbase and Dapper, I᾿m sharing my soon-to-be NFT old little league baseball card, which someone recently found and sent to me... 😂 @mcomicconnect has the backstory on my baseball card.”</p>" />
            <SocialMediaPost />
            <LineBreak />
            <Box textAlign="center" my={8}>
                <Heading as='h2' content='Auction for Mark Zuckerberg Collectibles September 24 - 28, 2022' />
            </Box>
            <Grid container='true' justifyContent='center'>
                <Box maxWidth={1200}>
                    <Carousel card media={[
                        "zuck_card_watermarked_back_c017ce49f8.jpg",
                        "zuck_card_watermarked_front_6fec0517ee.jpg", ]}/>
                </Box>
            </Grid>
            <LineBreak />
            <Box textAlign="center" my={8}>
                <Heading as='h2' content='Photos and Video of the Original 1992 Mark Zuckerberg Baseball Card & NFT' />
            </Box>
            <Carousel video media={[
                "comic-connect-1992-mark-zuckerberg-rookie-card-poster-frame-NFT.jpg",
                "comicconnect-mark-zuckerberg-rookie-card-front-middle.jpg",
                "comicconnect-mark-zuckerberg-rookie-card-day-camp-1992-with-card.jpg",
                "comicconnect-mark-zuckerberg-rookie-card-day-camp-1992-bear.jpg",
                "comicconnect_mark_zuckerberg_rookie_card_day_camp_counselor_c92b07296f.jpg",
                "comicconnect_mark_zuckerberg_rookie_card_irvin_simon_c85982ae3a.jpg",
                "comicconnect_mark_zuckerberg_rookie_card_day_camp_1993_a9968c0984.jpg" ]}/>
            <LineBreak />
            <Box textAlign="center" my={8}>
                <Heading as='h2' width={['auto', 'auto', 'auto', 'auto', 500]} content='Tell your friends about the Mark Zuckerberg baseball card & NFT auction!' />
            </Box>
            <Box textAlign="center" mb={5}>
                <Share />
            </Box>
        </Layout>
    )
}

export default HomePage
